import React from "react";
import Calculator from "../../components/calculators/calculator";
import {
  Section,
  Container,
  PageTitle,
  SectionTitle,
  Equation,
  Note,
  InlineEquation,
  Details,
  Summary,
  Centered,
} from "../../components/calculators/calculators-layout";
import {
  useFootnotes,
  Footnotes,
} from "../../components/hooks/use-footnotes";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import MathMLConstants from "../../components/calculators/mathml-constants";
import CALCULATORS_CONSTANTS from "../../components/calculators/constants";
import { Calculators } from ".";

const notes = [
  "J. R. Werber, et al., The critical need for increased selectivity, not increased water permeability, for desalination membranes, Environ. Sci. Technol. Lett. 3 (2016) 112–120.",
  "G.M. Geise, et al., Fundamental water and salt transport properties of polymeric materials, Prog. Polym. Sci. 39 (2014) 1–42.",
  "D.R. Paul, Relation between hydraulic permeability and diffusion in homogeneous swollen membranes, J. Polym. Sci. B Polym. Phys. 11 (1973) 289–296.",
];

const MembranePerformanceCalculatorPage = () => {
  const [footnotes] = useFootnotes(notes);

  return (
    <Layout>
      <Seo title="Membrane Performance Calculator" />
      <Section>
        <Container>
          <PageTitle>Membrane Performance Calculator</PageTitle>
        </Container>
        <Step1 />
      </Section>
      <Section>
        <Step2 />
      </Section>
      <Section>
        <Step3 />
      </Section>
      <Section>
        <Step4 />
      </Section>
      <Section>
        <Step5 />
      </Section>
      <Section>
        <Step6 />
      </Section>
      <Section>
        <Container>
          <Footnotes footnotes={footnotes} />
        </Container>
      </Section>
      <hr />
      <Calculators />
    </Layout>
  );
};
export default MembranePerformanceCalculatorPage;

const WaterPermeabilityCoefficientCalculator = () => (
  <Centered>
    <Calculator
      fields={[
        {
          name: "J_W_pure",
          label: (
            <>
              Measure pure water flux (no solutes present),{" "}
              <InlineEquation math={MathMLConstants.J_W_pure} /> =
            </>
          ),
          unit: (
            <>
              L m<sup>-2</sup> h<sup>-1</sup>
            </>
          ),
        },
        {
          name: "deltaP",
          label: (
            <>
              Fill in applied hydraulic pressure,{" "}
              <InlineEquation math={MathMLConstants.deltaP} /> =
            </>
          ),
          unit: "bar",
        },
      ]}
      outputs={[
        {
          name: "A",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants.A} /> =
            </>
          ),
          unit: (
            <>
              L m<sup>-2</sup> h<sup>-1</sup> bar<sup>-1</sup>
            </>
          ),
          calculate: ({ J_W_pure, deltaP }) => J_W_pure / deltaP,
        },
      ]}
    />
  </Centered>
);

const Step1 = () => (
  <>
    <Container>
      <SectionTitle>
        Step 1. Water permeability coefficient,{" "}
        <InlineEquation math={MathMLConstants.A} />
      </SectionTitle>
      <Equation math={MathMLConstants.waterPermeability} />
    </Container>
    <Container>
      <WaterPermeabilityCoefficientCalculator />
      <Details>
        <Summary>
          <span className="is-italic">
            <InlineEquation math={MathMLConstants.A} /> can also be calculated
            using solutes, but read the <u>note</u> on how to do it correctly.
          </span>
        </Summary>
        <p className="">
          The presence of solutes in a feed solution will increase the osmotic
          pressure difference across the membrane{" "}
          <b>
            (∆π<i><sub>f</sub></i> = π<i><sub>f</sub></i> – π<i><sub>p</sub></i>)
          </b>
          , reducing the net effective driving force. To account for this when
          calculating <InlineEquation math={MathMLConstants.A} />, the following
          equation needs to be used:
        </p>
        <Equation math={MathMLConstants.waterPermeabilityWithSolutes} />
        <Note>
          <b>
            ∆π<sub></sub>f
          </b>{" "}
          can be calculated{" "}
          <a href="/calculators/osmotic-pressure-calculator" target={"_blank"}  rel='noreferrer'>
            <u>here</u>
          </a>
          . The feed-side mass transfer coefficient (
          <b>
            k<sub>f</sub>
          </b>
          ) can be calculated{" "}
          <a
            href="/calculators/concentration-polarization-calculator"
            target={"_blank"}
            rel='noreferrer'
          >
            <u>here</u>
          </a>
          .
        </Note>
      </Details>
    </Container>
  </>
);

const ObservedSoluteRejectionCalculator = () => (
  <Centered>
    <Calculator
      fields={[
        {
          name: "C_f",
          label: (
            <>
              Measure feed solute concentration,{" "}
              <InlineEquation math={MathMLConstants.C_f} /> =
            </>
          ),
          unit: (
            <>
              mol L<sup>-1</sup>
            </>
          ),
        },
        {
          name: "C_p",
          label: (
            <>
              Measure permeate solute concentration,{" "}
              <InlineEquation math={MathMLConstants.C_p} /> =
            </>
          ),
          unit: (
            <>
              mol L<sup>-1</sup>
            </>
          ),
        },
      ]}
      outputs={[
        {
          name: "R_obs",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants.R_obs} /> =
            </>
          ),
          unit: <>unitless</>,
          calculate: ({ C_f, C_p }) => 1 - C_p / C_f,
        },
        {
          name: 'deltaC_f',
          display: false,
          calculate:({C_f, C_p}) => C_f - C_p
        }
      ]}
    />
  </Centered>
);

const Step2 = () => (
  <>
    <Container>
      <SectionTitle>
        Step 2. Observed solute rejection,{" "}
        <InlineEquation math={MathMLConstants.R_obs} />
      </SectionTitle>
      <Equation math={MathMLConstants.observedSoluteRejection} />
    </Container>
    <Container>
      <ObservedSoluteRejectionCalculator />
      <Note>
        <InlineEquation math={MathMLConstants.R_obs} /> is generally reported
        between 0 and 100%, but it should be a value between 0 and 1 for the
        calculation of <InlineEquation math={MathMLConstants.B} /> (step 4)
      </Note>
    </Container>
  </>
);

const SoluteFluxCalculator = () => (
  <Centered>
    <Calculator
      fields={[
        {
          name: "J_W",
          label: (
            <>
              Measure water flux (with solutes present),{" "}
              <InlineEquation math={MathMLConstants.J_W} /> =
            </>
          ),
          unit: (
            <>
              L m<sup>-2</sup> h<sup>-1</sup>
            </>
          ),
        },
      ]}
      outputs={[
        {
          name: "J_S",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants.J_S} /> =
            </>
          ),
          unit: (
            <>
              mol m<sup>-2</sup> h<sup>-1</sup>
            </>
          ),
          calculate: ({ J_W, C_p }) => J_W * C_p,
        },
      ]}
      dependencies={['C_p']}
    />
  </Centered>
);

const Step3 = () => (
  <>
    <Container>
      <SectionTitle>
        Step 3. Solute flux, <InlineEquation math={MathMLConstants.J_S} />
      </SectionTitle>
      <Equation math={MathMLConstants.soluteFlux} />
    </Container>
    <Container>
      <SoluteFluxCalculator />
      <Note>
        <InlineEquation math={MathMLConstants.C_p} /> taken from step 2
      </Note>
    </Container>
  </>
);

const SolutePermeabilityCoefficientCalculator = () => (
  <Centered>
    <Calculator
      fields={[
        {
          name: "deltaC_f",
          label: (
            <>
              Calculate solute concentration difference across membrane,{" "}
              <InlineEquation
                math={MathMLConstants.soluteConcentrationDifference}
              />{" "}
              =
            </>
          ),
          unit: (
            <>
              mol L<sup>-1</sup>
            </>
          ),
          calculate:({deltaC_f}) => deltaC_f
        },
        {
          name: "k_sol",
          label: (
            <>
              Enter solute mass transfer coefficient,{" "}
              <InlineEquation math={MathMLConstants.k_sol} /> =
            </>
          ),
          unit: (
            <>
              L m<sup>-2</sup> h<sup>-1</sup>
            </>
          ),
        },
      ]}
      outputs={[
        {
          name: "B",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants.B} /> =
            </>
          ),
          unit: (
            <>
              L m<sup>-2</sup> h<sup>-1</sup>
            </>
          ),
          calculate: ({ deltaC_f, k_sol, J_W, J_S }) => {
            return J_S / (deltaC_f * Math.exp(J_W / k_sol));
          },
        },
      ]}
      dependencies={['J_W', 'J_S']}
    />
  </Centered>
);

const Step4 = () => (
  <>
    <Container>
      <SectionTitle>
        Step 4. Solute permeability coefficient,{" "}
        <InlineEquation math={MathMLConstants.B} />
      </SectionTitle>
      <Equation math={MathMLConstants.solutePermeabilityCoefficient} />
    </Container>
    <Container>
      <SolutePermeabilityCoefficientCalculator />
      <Note>
        <InlineEquation math={MathMLConstants.C_f} />,{" "}
        <InlineEquation math={MathMLConstants.C_p} />,{" "}
        <InlineEquation math={MathMLConstants.J_W} />, and{" "}
        <InlineEquation math={MathMLConstants.J_S} /> taken from steps 2–3.{" "}
        <InlineEquation math={MathMLConstants.k_sol} /> can be considered equal
        to the feed-side mass transfer coefficient (
        <b>
          k
          <sub>f</sub>
        </b>
        ) for single-solute solutions. Details for calculating{" "}
        <b>
          k<sub>f</sub>
        </b>{" "}
        can be found{" "}
        <a
          href="/calculators/concentration-polarization-calculator"
          target={"_blank"}
          rel='noreferrer'
        >
          <u>here</u>
        </a>
        . If unavailable,{" "}
        <b>
          k<sub>f</sub>
        </b>{" "}
        and{" "}
        <b>
          k<sub>sol</sub>
        </b>{" "}
        can be roughly estimated as 100 L m<sup>-2</sup> h<sup>-1</sup>. For
        more information on the estimation of{" "}
        <InlineEquation math={MathMLConstants.k_f} /> and{" "}
        <InlineEquation math={MathMLConstants.k_sol} />, please see the page
        concentration polarization{" "}
        <u>
          <a href="/concentration-polarization/" target={"_blank"} rel='noreferrer'>
            here
          </a>
        </u>
      </Note>
    </Container>
  </>
);

const RealRejectionAndWaterSaltPermselectivityCalculator = () => (
  <Centered>
    <Calculator
      fields={[
        {
          name: "deltaPi_f",
          label: (
            <>
              Enter osmotic pressure difference across membrane,{" "}
              <InlineEquation
                math={MathMLConstants.osmoticPressureDifference}
              />{" "}
              =
            </>
          ),
          unit: <>bar</>,
        },
        {
          name: "k_f",
          label: (
            <>
              Enter feed-side mass transfer coefficient,{" "}
              <InlineEquation math={MathMLConstants.k_f} /> =
            </>
          ),
          unit: (
            <>
              L m<sup>-2</sup> h<sup>-1</sup>
            </>
          ),
        },
        {
          name: "Temperature",
          label: (
            <>
              Enter temperature,{" "}
              <InlineEquation math={MathMLConstants.Temperature} /> =
            </>
          ),
          unit: (
            <>
              K
            </>
          ),
        },
      ]}
      outputs={[
        {
          name: "R_real",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants.R_real} /> =
            </>
          ),
          unit: <>unitless</>,
          calculate: ({ deltaP, deltaPi_f, k_f, A, B, J_W }) =>
            ((A / B) * (deltaP - deltaPi_f * Math.exp(J_W / k_f))) /
            (1 + (A / B) * (deltaP - deltaPi_f * Math.exp(J_W / k_f))),
        },
        {
          name: "P_WP_S",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants["P_W/P_S"]} /> =
            </>
          ),
          unit: <>unitless</>,
          calculate: ({ A, B, Temperature }) =>
            (A / B) *
            ((CALCULATORS_CONSTANTS.R_g * Temperature) /
              CALCULATORS_CONSTANTS.V_W),
        },
      ]}
      dependencies={['J_W', 'delta_P', 'A', 'B']}
    />
  </Centered>
);

const Step5 = () => (
  <>
    <Container>
      <SectionTitle>
        Step 5. Real rejection, <InlineEquation math={MathMLConstants.R_real} />{" "}
        and water-salt permselectivity,{" "}
        <InlineEquation math={MathMLConstants.P_W} />/
        <InlineEquation math={MathMLConstants.P_S} />
      </SectionTitle>
      <Equation math={MathMLConstants.realRejection} />
      <Equation math={MathMLConstants.waterSaltPermselectivity} />
    </Container>
    <Container>
      <RealRejectionAndWaterSaltPermselectivityCalculator />
      <Note>
        <b>
          <i>
            ∆π<sub>f</sub>
          </i>
        </b>{" "}
        can be calculated{" "}
        <a href="/calculators/osmotic-pressure-calculator" target={"_blank"} rel='noreferrer'>
          <u>here</u>
        </a>
        . Details for calculating <InlineEquation math={MathMLConstants.k_f} />{" "}
        can be found{" "}
        <a
          href="/calculators/concentration-polarization-calculator"
          target={"_blank"}
        >
          <u>here</u>
        </a>
        . If unavailable, <InlineEquation math={MathMLConstants.k_f} /> can be
        roughly estimated as 100 L m<sup>-2</sup> h<sup>-1</sup>. For more
        information on the estimation of{" "}
        <InlineEquation math={MathMLConstants.k_f} /> and{" "}
        <InlineEquation math={MathMLConstants.k_sol} />, please see the page
        concentration polarization{" "}
        <u>
          <a href="/concentration-polarization/" target={"_blank"} rel='noreferrer'>
            here
          </a>
        </u>
        . R<sub>g</sub>, T, and V<sub>W</sub> are the ideal gas constant (cm
        <sup>3</sup> bar K<sup>-1</sup> mol<sup>-1</sup>), temperature (K), and
        molar volume of water (cm<sup>3</sup> mol<sup>-1</sup>), respectively.
        All other values taken from steps 1 and 4.
      </Note>
    </Container>
  </>
);

const DiffusiveSaltAndWaterPermeabilityCalculator = () => (
  <Centered>
    <Calculator
      fields={[
        {
          name: "delta",
          label: (
            <>
              Measure membrane active layer thickness,{" "}
              <InlineEquation math={MathMLConstants.delta} /> =
            </>
          ),
          unit: <>nm</>,
        },
      ]}
      outputs={[
        {
          name: "P_S",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants["P_S"]} /> =
            </>
          ),
          unit: (
            <>
              cm<sup>2</sup> s<sup>-1</sup>
            </>
          ),
          calculate: ({ delta, B }) => 2.7778 * Math.pow(10, -12) * B * delta,
        },
        {
          name: "P_W",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants["P_W"]} /> =
            </>
          ),
          unit: (
            <>
              cm<sup>2</sup> s<sup>-1</sup>
            </>
          ),
          calculate: ({ P_WP_S, P_S }) => P_WP_S * P_S,
        },
      ]}
      dependencies={['P_WP_S']}
    />
  </Centered>
);

const Step6 = () => (
  <>
    <Container>
      <SectionTitle>
        Step 6. Diffusive Water and Solute Permeabilities,{" "}
        <InlineEquation math={MathMLConstants.P_W} /> and{" "}
        <InlineEquation math={MathMLConstants.P_S} />
      </SectionTitle>
      <Equation math={MathMLConstants.diffusiveSaltPermeability} />
      <Equation math={MathMLConstants.diffusiveWaterPermeability} />
    </Container>
    <Container>
      <DiffusiveSaltAndWaterPermeabilityCalculator />
      <Note>
        <InlineEquation math={MathMLConstants.B} /> and{" "}
        <InlineEquation math={MathMLConstants["P_W/P_S"]} /> taken from steps
        4–5. The factor of 2.778×10<sup>-12</sup> is for unit conversion to cm
        <sup>2</sup> s<sup>-1</sup> when using units of nm for <b>δ</b> and L m
        <sup>-2</sup> h<sup>-1</sup> for <b>B</b>.
      </Note>
    </Container>
  </>
);
